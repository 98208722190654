import classNames from '../../classNames'

export default async app => {
  const inputs = [...document.querySelectorAll(`.${classNames.input}`)]
  if (!inputs.length) return

  const { default: Input } = await import(/* webpackChunkName: "input" */ './Input')

  app.input = new Input(app)
  app.input.init()
}
