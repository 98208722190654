import { isWebkit } from '../helpers'
import classNames from '../classNames'

export default async app => {
  if (isWebkit) return
  const containers = [...document.querySelectorAll(`.${classNames.scrollbar}`)]

  if (!containers.length) return

  app.scrollbars = []
  let inited = false
  let SimpleBar

  const init = () => {
    if (inited) return

    containers.forEach(container => {
      if (container.getAttribute('data-simplebar') === 'init') return

      const { media } = container.dataset

      if (media && !window.matchMedia(media).matches) return

      const simplebar = new SimpleBar(container)

      app.scrollbars.push({
        container,
        simplebar,
      })

      if (app.scrollbars.length === containers.length) inited = true
    })
  }

  const { default: plugin } = await import(/* webpackChunkName: "simplebar" */ 'simplebar')

  SimpleBar = plugin
  init()
}
