import classNames from '../../classNames'

export default async app => {
  const dropdowns = [...document.querySelectorAll(`.${classNames.dropdown}`)]

  if (!dropdowns.length) return

  const { default: Dropdown } = await import(/* webpackChunkName: "dropdown" */ './Dropdown')
  app.dropdown = new Dropdown(dropdowns)
  app.dropdown.init()
}
