import { isTouch } from '../helpers'
import { NO_TOUCH, IS_READY } from '../constants'

const setTouch = DOC => {
  if (!isTouch) {
    DOC.classList.add(NO_TOUCH)
  }
}

const setReady = DOC => {
  DOC.classList.add(IS_READY)
}

export default function setHTMLClassNames({ dom: { root: DOC } }) {
  setTouch(DOC)
  setReady(DOC)
}
