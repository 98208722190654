import classNames from '../../classNames'

export default async app => {
  const links = document.querySelectorAll(`.${classNames.link}`)
  if (!links.length) return

  const { default: FancyLink } = await import(/* webpackChunkName: "fancyLink" */ './FancyLink')

  links.forEach(link => {
    const fancyLink = new FancyLink(link)
    fancyLink.init()

    app.fancyLinks.push(fancyLink)
  })
}
