import classNames from '../classNames'

export default () => {
  function onClick({ target }) {
    const btnToTop = target.closest(`.${classNames.scrollTo.top}`)

    const scrollToTop = () => {
      // const top = target.getBoundingClientRect().top + BODY.scrollTop

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    if (btnToTop) scrollToTop()
  }

  document.addEventListener('click', onClick)
}
