import classNames from '../classNames'
import { IS_HIDDEN } from '../constants'
import BEMBlock from '../lib/BEMBlock'

export default () => {
  const btn = document.querySelector('.send_code_again')
  if (!btn) return

  const onClick = ({ currentTarget }) => {
    const countdown = document.querySelector(`.${classNames.countdown}`)
    if (!countdown) return

    const countdownWrap = countdown.closest('.sms_wait_time')

    let start = 59

    BEMBlock(countdownWrap, 's-login__control').removeMod(IS_HIDDEN)
    if (currentTarget) BEMBlock(currentTarget, 's-login__control').addMod(IS_HIDDEN)

    const interval = setInterval(() => {
      start -= 1
      countdown.innerHTML = start

      if (start <= 0) {
        BEMBlock(countdownWrap, 's-login__control').addMod(IS_HIDDEN)
        if (currentTarget) BEMBlock(currentTarget, 's-login__control').removeMod(IS_HIDDEN)
        clearInterval(interval)
      }
    }, 1000)
  }

  btn.addEventListener('click', onClick)
}
