export default {
  input: 'input:not(.input--checkbox):not(.input--radio):not(.input--file)',
  lazy: 'js-lazy',
  image: 'js-image',
  title: 'js-animated-title',
  animatedSection: 'js-animated-section',
  popup: 'js-popup',
  mask: 'js-mask-input',
  select: 'js-select',
  textarea: 'js-textarea',
  scrollbar: 'js-scrollbar',
  picker: 'js-picker',
  dropdown: 'js-dropdown',
  countdown: 'js-countdown',
  cards: {
    wrap: 'js-cards',
    quantityTitle: 'js-cards-all',
  },
  rangeSlider: {
    wrap: 'js-range-slider',
    input: 'js-range-slider-input',
  },
  counter: 'js-counter',
  link: 'js-fancy-link',
  codeField: 'js-code-field',
  hiddenFieldsForm: {
    wrap: 'js-hidden-fields-form',
    field: 'js-field-auxiliary',
    select: 'js-hidden-fields-form-select',
  },
  formBlock: {
    btn: 'js-form-block-btn',
    block: 'js-form-block',
  },
  cabinet: {
    wrap: 'js-cabinet',
    link: 'js-cabinet-link',
  },
  scrollTo: {
    top: 'js-scroll-top',
  },
  menu: {
    burger: 'js-burger',
    menu: 'js-menu',
    close: 'js-menu-close',
  },
  slider: {
    container: 'js-slider',
    slider: {
      wrap: 'slider__wrap',
      prev: 'js-slider-prev',
      next: 'js-slider-next',
      pagination: 'js-slider-pagination',
      slide: 'swiper-slide',
    },
    plugin: {
      container: 'swiper-container',
      initialized: 'swiper-container-initialized',
      activeSlide: 'swiper-slide-active',
      btn: {
        disabled: 'swiper-button-disabled',
      },
    },
  },
}
