import classNames from '../../classNames'

export default async app => {
  const wraps = [...document.querySelectorAll(`.${classNames.cards.wrap}`)]
  if (!wraps.length) return

  const { default: CardsToggler } = await import(
    /* webpackChunkName: "CardsToggler" */ './CardsToggler'
  )
  app.cardsToggler = new CardsToggler()
  app.cardsToggler.init()
}
