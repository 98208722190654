import { async } from 'regenerator-runtime'
import classNames from '../classNames'
import { preventScroll, allowScroll } from '../helpers'

export default async app => {
  const popups = [...document.querySelectorAll(`.${classNames.popup}`)]
  if (!popups.length) return

  const { default: Popup } = await import(/* webpackChunkName: "popup" */ 'popup-simple')

  app.popup = new Popup({
    preventScroll: false,
    escapeHandler: false,
    closeOnOverlayClick: false,
  })

  app.popup.onOpen = () => {
    app.updateState({
      hasPopupOpen: true,
    })

    preventScroll()
  }

  app.popup.onClose = () => {
    app.updateState({
      hasPopupOpen: app.popup.openPopups.length > 0,
    })

    if (!app.state.hasPopupOpen && !app.state.hasMenuOpen) allowScroll()
  }

  app.popup.init()

  const handleCartPopup = () => {
    const [target] =
      app.popup.popups.length > 0
        ? app.popup.popups.filter(({ id }) => id === 'popup-cart-min-order')
        : []

    if (target) app.popup.openTarget(target)
  }

  const openBanner = () => {
    const [bannerPopup] = popups.filter(({ id }) => id === 'popup-banner')
    if (bannerPopup) app.popup.openTarget(bannerPopup)
  }

  const openAgePopup = () => {
    const [agePopup] = popups.filter(({ id }) => id === 'popup-check-age')
    if (agePopup) app.popup.openTarget(agePopup)
  }

  handleCartPopup()
  setTimeout(openBanner, 1000)
  openAgePopup()
}
