import classNames from '../../classNames'

export default async app => {
  const images = [...document.querySelectorAll(`.${classNames.image}`)]
  if (!images.length) return

  const { default: ImageAnimator } = await import(
    /* webpackChunkName: "ImageAnimator" */ './ImageAnimator'
  )

  app.imageAnimator = new ImageAnimator()
  app.imageAnimator.init()
}
