import classNames from '../../classNames'

export default async app => {
  const sliders = [...document.querySelectorAll(`.${classNames.rangeSlider.wrap}`)]

  if (!sliders.length) return

  const { default: RangeSlider } = await import(
    /* webpackChunkName: "range-slider" */ './RangeSlider'
  )

  app.rangeSlider = new RangeSlider()
  app.rangeSlider.init()
}
