import classNames from '../classNames'

export default () => {
  const addCheckoutRadiosClassNames = () => {
    const radios = [...document.querySelectorAll('#orders-payment_type label')]

    if (!radios.length) return

    const firstRadio = radios[0]
    firstRadio.classList.add('form__field--auxiliary')
    firstRadio.classList.add(classNames.hiddenFieldsForm.field)
    firstRadio.setAttribute('data-for', '["courier-kiev-suburb", "courier-kiev"]')
  }

  const toggleFormBlock = () => {
    const onClick = ({ target }) => {
      const btn = target.closest('.js-toggle-btn')
      if (!btn) return

      const block = btn.closest('.form').querySelector('.form__form')

      if (block) {
        if (block.getAttribute('data-state') === 'hidden') {
          block.removeAttribute('data-state')
        } else {
          block.setAttribute('data-state', 'hidden')
        }
      }
    }

    document.addEventListener('click', onClick)
  }

  toggleFormBlock()
  addCheckoutRadiosClassNames()
}
