import animateInView from './animateInView'
import classNames from '../../classNames'

export default app => {
  const importAnimation = async (section, path) => {
    try {
      const { default: animation } = await import(`${path}`)

      const { enter, leave } = animation(section, app)

      return {
        enter,
        leave,
      }
    } catch (error) {
      console.error('Animation script import error', error)
    }

    return {}
  }

  const getElements = name =>
    name === 'section'
      ? [...document.querySelectorAll(`.${classNames.animatedSection}:not([data-section])`)]
      : [...document.querySelectorAll(`.${classNames.animatedSection}[data-section="${name}"]`)]

  const getSectionData = (name, pathToScript) => ({
    els: getElements(name),
    onEnter: async section => {
      const { enter } = await importAnimation(section, pathToScript)
      if (enter) enter()
    },
    onLeave: async section => {
      const { leave } = await importAnimation(section, pathToScript)
      if (leave) leave()
    },
  })

  const sections = {
    section: getSectionData('section', './sectionAnimation'),
    'link-cards': getSectionData('link-cards', './linksSectionAnimation'),
    info: getSectionData('info', './infoAnimation'),
    blog: getSectionData('blog', './blogAnimation'),
    slider: getSectionData('slider', './sliderSectionAnimation'),
    brands: getSectionData('brands', './brandsAnimation'),
    news: getSectionData('news', './newsAnimation'),
  }

  Object.values(sections).forEach(async ({ els, onEnter, onLeave }) => {
    if (!els.length) return

    const { header } = app.dom
    header.style.opacity = 0

    const { default: gsap } = await import(/* webpackChunkName: "gsap" */ 'gsap')

    const tl = gsap.timeline()
    tl.to(header, { opacity: 1, duration: 1 }, '+=1.5')

    els.forEach(section => {
      section.style.opacity = 0

      animateInView({
        once: false, // TODO: Uncomment, if need to add infinity animations triggering.
        section,
        app,
        onEnter,
        onLeave,
      })
    })
  })
}
