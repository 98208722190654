import classNames from '../../classNames'

export default async app => {
  const selects = [...document.querySelectorAll(`.${classNames.select}`)]

  if (!selects.length) return

  const { default: Select } = await import(/* webpackChunkName: "select" */ './Select')
  app.select = new Select(app)
  app.select.init()

  setTimeout(() => {
    app.select.update()
  }, 500)
}
