import classNames from '../classNames'

export default async function animateTitles(app) {
  const titles = [...document.querySelectorAll(`.${classNames.title}`)]

  if (!titles.length) return

  const { default: Splitting } = await import(/* webpackChunkName: "splitting" */ 'splitting')
  const { default: gsap } = await import(/* webpackChunkName: "gsap" */ 'gsap')

  const animateTitle = ({ title, chars }) => {
    const tl = gsap.timeline()

    tl.to(title, { opacity: 1 }).fromTo(
      chars,
      {
        y: '110%',
      },
      {
        y: '0%',
        stagger: 0.015,
      }
    )
  }

  const hideTitle = ({ title, chars }) => {
    const charsReverse = [...chars].reverse()
    const tl = gsap.timeline()

    tl.fromTo(
      charsReverse,
      {
        y: '0%',
      },
      {
        y: '110%',
        stagger: 0.015,
      }
    ).to(title, { opacity: 0 })
  }

  titles.forEach(title => {
    const [splittedTitle] = Splitting({ target: title, by: 'chars' })
    const { words, chars } = splittedTitle

    app.titles.push({
      node: title,
      words,
      chars,
      animate: animateTitle.bind(null, { title, chars }),
      hide: hideTitle.bind(null, { title, chars }),
    })
  })
}
