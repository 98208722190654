import { debounce } from 'throttle-debounce'
import { DELAYS } from '../constants'
import { outerHeight } from '../helpers'

const setRecommendSliderPadding = (props = {}) => {
  const { slider = document.querySelector('.s-recommend__slider') } = props
  if (!slider) return

  if (!window.matchMedia('(min-width: 1200px)').matches) {
    slider.style.paddingLeft = ''
    return
  }

  const GAP = 25
  const title = document.querySelector('.s-recommend__title')
  const { left } = title.getBoundingClientRect()

  slider.style.paddingLeft = `${left - GAP}px`
}

export const setMainSliderControlsPosition = (props = {}) => {
  const { slider = document.querySelector('.slider--main__wrap'), slide } = props

  if (!slider) return
  const controls = slider.querySelector('.slider__controls')

  if (window.matchMedia('(min-width: 576px)').matches) {
    controls.style.top = ''
    return
  }

  const title = slide ? slide.querySelector('.title') : slider.querySelector('.title')
  const image = slide ? slide.querySelector('.image') : slider.querySelector('.image')

  const top = outerHeight(title) + outerHeight(image)
  controls.style.top = `${top}px`
}

export default () => {
  const recommendSlider = document.querySelector('.s-recommend__slider')
  const mainSlider = document.querySelector('.slider--main__wrap')

  if (recommendSlider || mainSlider) {
    setRecommendSliderPadding()
    setMainSliderControlsPosition()

    const handleResize = () => {
      setRecommendSliderPadding()
      setMainSliderControlsPosition()
    }

    const onResize = debounce(DELAYS.long, handleResize)
    window.addEventListener('resize', onResize)
  }
}
