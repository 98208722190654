import classNames from '../classNames'
import { IS_ACTIVE, STATE } from '../constants'

export default app => {
  const forms = [...document.querySelectorAll(`.${classNames.hiddenFieldsForm.wrap}`)]

  if (!forms.length) return

  const toggleField = ({ form, select }) => {
    const fields = [...form.querySelectorAll(`.${classNames.hiddenFieldsForm.field}`)]
    const visibleFields = fields.filter(({ dataset: { for: forAttribute } }) => {
      const forValues = forAttribute.indexOf('[') === 0 ? JSON.parse(forAttribute) : [forAttribute]

      return forValues.filter(forValue => forValue === select.value)[0]
    })

    fields.forEach(field => field.removeAttribute(STATE))
    visibleFields.forEach(field => field.setAttribute(STATE, IS_ACTIVE))
  }

  const setFormAction = ({ wrap, form, select, paymentInput }) => {
    const actions = {
      courier: wrap.querySelector('.kr_action').value,
      NP: wrap.querySelector('.np_action').value,
      liqpay: wrap.querySelector('.liqpay_action').value,
    }

    paymentInput = paymentInput || form.querySelector('#orders-payment_type [type="radio"]:checked')
    select = select || form.querySelector(`.${classNames.hiddenFieldsForm.select}`)

    if (!select || !paymentInput) return

    let action

    if (
      select.value === 'np-kiev' ||
      select.value === 'np-ukraine' ||
      select.value === 'np-ukraine-address'
    ) {
      action = actions.NP
    }

    if (
      (select.value === 'courier-kiev' || select.value === 'courier-kiev-suburb') &&
      +paymentInput.value === 1
    ) {
      action = actions.courier
    }

    if (
      (select.value === 'courier-kiev' || select.value === 'courier-kiev-suburb') &&
      +paymentInput.value === 0
    ) {
      action = actions.liqpay
    }

    form.setAttribute('action', action)
  }

  forms.forEach(form => {
    const select = form.querySelector(`.${classNames.hiddenFieldsForm.select}`)

    toggleField({ form, select })
  })

  const onChange = ({ target }) => {
    const deliveryTypeSelect = target.closest(`.${classNames.hiddenFieldsForm.wrap}`)
    const savedAddressesSelect = target.id === 'orders-saved-addresses' ? target : null

    const handleTypes = () => {
      const select = target.closest(`.${classNames.hiddenFieldsForm.select}`)
      const paymentInput = target.closest('#orders-payment_type [type="radio"]')
      const form = deliveryTypeSelect.querySelector('form')

      if (select) {
        toggleField({ form, select })
        setFormAction({ wrap: deliveryTypeSelect, form, select })
      }

      if (paymentInput) setFormAction({ wrap: deliveryTypeSelect, form, paymentInput })
    }

    const handleAddresses = () => {
      const { value } = savedAddressesSelect
      const valuesArr = value.split('|')

      const values = {
        city: valuesArr[0],
        street: valuesArr[1],
        building: valuesArr[2],
        flat: valuesArr[3],
      }

      const inputs = {
        city: document.getElementById('orders-city_name'),
        street: document.getElementById('orders-street'),
        building: document.getElementById('orders-house'),
        flat: document.getElementById('orders-flat'),
      }

      Object.values(inputs).forEach((input, i) => {
        const text = Object.values(values)[i]

        input.value = text || ''

        if (app.input) app.input.update()
      })
    }

    if (deliveryTypeSelect) handleTypes()
    if (savedAddressesSelect) handleAddresses()
  }

  document.addEventListener('change', onChange)
}
