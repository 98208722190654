import classNames from '../classNames'
import { IS_DISABLED } from '../constants'
import BEMBlock from '../lib/BEMBlock'

export default () => {
  const counterInput = document.getElementById('item_count')
  const counters = [...document.querySelectorAll(`.${classNames.counter}`)]

  const toggleButton = ({ counterInput: input, incrButton, itemsLeft }) => {
    if (+input.value === itemsLeft) {
      incrButton.setAttribute('disabled', '')
      BEMBlock(incrButton, 'counter__btn').addMod(IS_DISABLED)
    } else if (+input.value < itemsLeft) {
      incrButton.removeAttribute('disabled')
      BEMBlock(incrButton, 'counter__btn').removeMod(IS_DISABLED)
    }
  }

  const handleItemsLeft = itemsLeft => {
    if (counterInput) {
      const counter = counterInput.closest(`.${classNames.counter}`)
      const incrButton = counter.querySelector(`.${classNames.counter} .counter__btn--incr`)

      toggleButton({ counterInput, incrButton, itemsLeft })
    }
  }

  const onClick = ({ target }) => {
    const wrap = target.closest(`.${classNames.counter}`)
    if (!wrap) return

    let incrButton = target.closest(`.${classNames.counter} .counter__btn--incr`)
    const decrButton = target.closest(`.${classNames.counter} .counter__btn--decr`)

    const input = wrap.querySelector('.counter__input')
    const itemsLeft = +wrap.dataset.ostatok

    let quantity = +input.value || 0

    if (incrButton) quantity += 1
    if (decrButton) quantity -= 1

    if (quantity < 0) quantity = 0

    input.value = quantity

    if (itemsLeft) {
      if (decrButton) {
        incrButton = wrap.querySelector(`.${classNames.counter} .counter__btn--incr`)
      }

      toggleButton({
        counterInput: input,
        incrButton,
        itemsLeft,
      })
    }
  }

  document.addEventListener('click', onClick)

  counters.forEach(counter => {
    const itemsLeft = +counter.dataset.ostatok
    if (itemsLeft) handleItemsLeft(itemsLeft)
  })
}
