import regeneratorRuntime from 'regenerator-runtime'
import './public-path'
import { addGlobalVariable, isModernBrowser } from './helpers'

import loadPolyfills from './polyfills/loadPolyfills'
import setHTMLClassNames from './methods/setHTMLClassNames'
import animateTitles from './methods/animateTitles'
import scrollTo from './methods/scrollTo'
import setPopups from './methods/setPopups'
import setInputMask from './methods/setInputMask'
import setTextareaHeight from './methods/setTextareaHeight'
import setScrollbar from './methods/setScrollbar'
// import setPickers from './methods/setPickers'
// import addFormBlocks from './methods/addFormBlocks'
import handleCounter from './methods/handleCounter'
import setAnimations from './methods/animations'
// import handleForms from './methods/handleForms'
import setPaddings from './methods/setPaddings'
// import handleCodeField from './methods/handleCodeField'
import toggleCartFormFields from './methods/toggleCartFormFields'
// import toggleCardsVisibility from './methods/toggleCardsVisibility'
// import toggleFormTable from './methods/toggleFormTable'
import handleLoginForm from './methods/handleLoginForm'

import addCommonScripts from './methods/common'

import setLazy from './components/LazyLoader/setLazy'
import Menu from './components/Menu/Menu'
import setSlider from './components/Slider/setSlider'
import setImageAnimator from './components/ImageAnimator/setImageAnimator'
import setInputs from './components/Input/setInputs'
import setSelects from './components/Select/setSelects'
import setDropdowns from './components/Dropdown/setDropdowns'
import setRangeSliders from './components/RangeSlider/setRangeSliders'
// import initCabinetTabs from './components/Cabinet'
import setFancyLinks from './components/FancyLink/setFancyLinks'
import setCardsToggler from './components/CardsToggler/setCardsToggler'

class App {
  constructor() {
    this.methods = {}
    this.dom = {
      root: document.documentElement,
      body: document.body,
      header: document.querySelector('.header'),
    }
    this.LANGUAGE = this.dom.root.getAttribute('lang')
      ? this.dom.root.getAttribute('lang').split('-')[0]
      : 'en'
    this.state = {
      hasMenuOpen: false,
      hasPopupOpen: false,
    }

    this.menu = new Menu(this)

    this.lazyLoader = null
    this.slider = null
    this.popup = null
    this.input = null
    this.imageAnimator = null
    this.cardsToggler = null
    this.masks = []
    this.titles = []
    this.fancyLinks = []
  }

  updateState(state) {
    this.state = {
      ...this.state,
      ...state,
    }
  }

  initMethods() {
    this.methods = {
      setHTMLClassNames,
      setLazy,
      animateTitles,
      scrollTo,
      setSlider,
      setImageAnimator,
      setPopups,
      setInputMask,
      setInputs,
      setSelects,
      setTextareaHeight,
      setScrollbar,
      // setPickers,
      setDropdowns,
      setRangeSliders,
      // initCabinetTabs,
      // addFormBlocks,
      handleCounter,
      setAnimations,
      setFancyLinks,
      // handleForms,
      setPaddings,
      // handleCodeField,
      toggleCartFormFields,
      setCardsToggler,
      addCommonScripts,
      // toggleFormTable,
      handleLoginForm,
    }

    Object.values(this.methods).forEach(fn => fn(this))
  }

  init() {
    this.initMethods()

    this.menu.init()
  }
}

const init = () => {
  const app = new App()
  app.init()

  window.LANGUAGE = app.LANGUAGE

  addGlobalVariable({
    app,
    component: 'select',
    name: 'customSelect',
  })
  addGlobalVariable({
    app,
    component: 'input',
    name: 'appInput',
  })
  addGlobalVariable({
    app,
    component: 'lazyLoader',
    name: 'lazyLoader',
  })
  addGlobalVariable({
    app,
    component: 'popup',
    name: 'appPopup',
  })
  addGlobalVariable({
    app,
    component: 'cardsToggler',
    name: 'cardsToggler',
  })
}

if (isModernBrowser) {
  document.addEventListener('DOMContentLoaded', init)
} else {
  document.addEventListener('DOMContentLoaded', loadPolyfills.bind(null, init))
}
